import React from "react"

import "../components/privacypolicy.css"
import Footer from "../components/footer"
import Header2 from "../components/header2"
import Seo from "../components/seo"

const TermsAndConditions = () => {
  return (
    <>
      <Seo title="Terms and Conditions" />
      <Header2 />

      <div className="container mt-5 pt-5">
        <div>
          <h1 className="font-weight-bolder">Terms and Conditions</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              These Terms and Conditions constitute a legally binding agreement
              made between you, whether personally or on behalf of an entity
              (“you”) and Superior Merchants (“we,” “us,” or “our”), concerning
              your access to and use of the SuperiorMerchants.com website as
              well as any other media form, media channel, mobile website or
              mobile application related, linked, or otherwise connected to it
              (collectively, the “Site”).
            </p>
            <p>
              You agree that by accessing the Site, you have read, understood,
              and agree to be bound by all of these Terms and Conditions. If you
              do not agree with all these Terms and Conditions, you are
              expressly prohibited from using the Site and must discontinue use
              immediately.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">Intellectual Property</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              The Site may contain (or you may be sent via the Site) links to
              other websites (“Third-Party Websites”) as well as articles,
              photographs, text, graphics, pictures, designs, music, sound,
              video, information, applications, software, and other content or
              items belonging to or originating from third parties (“Third-Party
              Content”).
            </p>
            <p>
              {" "}
              Such Third-Party Websites and Third-Party Content are not
              investigated, monitored, or checked for accuracy, appropriateness,
              or completeness by us. We are not responsible for any Third-Party
              Websites accessed through the Site or any Third-Party Content
              posted on, available through, or installed from the Site,
              including the content, accuracy, offensiveness, opinions,
              reliability, privacy practices, or other policies of or contained
              in the Third-Party Websites or the Third-Party Content.
            </p>
            <p>
              Inclusion of, linking to, or permitting the use or installation of
              any Third-Party Websites or any Third-Party Content does not imply
              approval or endorsement thereof. If you decide to leave the Site
              and access the Third-Party Websites or to use or install any
              Third-Party Content, you do so at your own risk, and you should be
              aware these Terms and Conditions no longer govern.
            </p>
            <p>
              We strongly advise you to read the Third-Party Website terms and
              conditions and the privacy policies of any site or services you
              visit or use.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">Indemnification</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              You agree to defend, indemnify, and hold Superior Merchants
              harmless, including our subsidiaries, affiliates, and all our
              respective officers, agents, partners, and employees, from and
              against any loss, damage, liability, claim, or demand, including
              reasonable attorneys’ fees and expenses, made by any third party
              due to or arising out of:
            </p>

            <ol>
              <li>use of the Site</li>
              <li>breach of these Terms and Conditions</li>
              <li>
                any breach of your representations and warranties outlined in
                these Terms and Conditions
              </li>
              <li>
                your violation of the rights of a third party, including but not
                limited to intellectual property rights
              </li>
              <li>
                any overt harmful act toward any other user of the Site with
                whom you connected via the Site
              </li>
            </ol>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">Limitation of Liability</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              In no event will we or our directors, employees, or agents be
              liable to you or any third party for any direct, indirect,
              consequential, exemplary, incidental, special, or punitive
              damages, including lost profit, lost revenue, loss of data, or
              other damages arising from your use of the site, even if we have
              been advised of the possibility of such damages.
            </p>
            <p>
              Notwithstanding anything to the contrary contained herein, our
              liability to you for any cause whatsoever and regardless of the
              form of the action will at all times be limited to the lesser of
              the amount paid, if any, by you to us during the ONE (1) month
              period before any cause of action arising or US$100. State laws
              may not allow limitations on implied warranties or the exclusion
              or limitation of certain damages.
            </p>
            <p>
              If certain State laws apply to you, some or all disclaimers found
              within these terms and conditions or limitations may not apply to
              you, and you may have additional rights.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">Exclusions</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              While the limitations above may not apply to you, some countries
              do not allow exclusions or limitations of liability for
              consequential damages, so please check with your jurisdiction
              before purchasing this insurance.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">Governing Law</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              These Terms and Conditions and your use of the Site are governed
              by and construed following the laws of the State of Florida
              applicable to agreements made and to be entirely performed within
              the State of Florida, without regard to its conflict of law
              principles.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder"></h1>
          <div className="underline" />
          <div className="mt-4">
            <p></p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">Changes</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              Supplemental terms and conditions or documents posted on the Site
              from time to time are hereby expressly incorporated herein by
              reference. In our sole discretion, we reserve the right to make
              changes or modifications to these Terms and Conditions at any time
              and for any reason.
            </p>
            <p>
              We will alert you about any changes by updating the “Last Updated”
              date of these Terms and Conditions, and you waive any right to
              receive specific notice of each such change.
            </p>
            <br />
            <br />
            <p>
              You must periodically review these Terms and Conditions to stay
              informed of updates. You will be subject to and will be deemed to
              have been made aware of and accepted the changes in any revised
              Terms and Conditions by your continued use of the Site after the
              date such revised Terms and Conditions are posted.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default TermsAndConditions
