import React from "react"
import { Link } from "gatsby"
import { MdKeyboardArrowRight } from "react-icons/md"

import logo from "../images/logo.png"

import "bootstrap/dist/css/bootstrap.min.css"
import "./header2.css"

const Header2 = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top  shadow p-3 mb-5 ">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            src={logo}
            alt=""
            className="d-inline-block align-text-top logo mb-0"
          />
        </Link>
        <button
          className="navbar-toggler h"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav nav-ul">
            <li className="nav-item">
              <Link
                id="navlink1"
                className="nav-link"
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link id="navlink1" className="nav-link" to="/about-us">
                About
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle navlink5 "
                to="/categories"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Categories
              </Link>
              <ul
                className="dropdown-menu  catgriesdropdownmenu"
                aria-labelledby="navbarDropdown"
              >
                <li className="catgriessublinks">
                  <Link
                    className="dropdown-item catgriessubitem"
                    to="/medical-software"
                  >
                    <MdKeyboardArrowRight size="25px" />
                    Medical Software
                  </Link>
                </li>
                <li className="catgriessublinks">
                  <Link
                    className="dropdown-item catgriessubitem"
                    to="/pos-system"
                  >
                    <MdKeyboardArrowRight size="25px" />
                    POS system
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header2
