import React from "react"
import "./footer.css"
import foterlogo from "../images/logo.png"
import { Link } from "gatsby"

const Mainfooter = () => {
  return (
    <footer className="footer-section">
      <div className=" main-footer-section">
        <div className="footer-content pt-5 pb-5">
          <div className="row justify-content-start justify-content-md-center">
            <div className="col-lg-3 col-md-4 col-sm-12 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/Home">
                    <img src={foterlogo} className="img-fluid" alt="logo" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>Providing all services for merchants at one spot.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 mb-30">
              <div className="footer-widget  footer-widget23">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-3 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Legal</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-text  ">
            <p style={{ textAlign: "center" }}>
              Copyright © SuperiorMerchants All rights reserved | Designed by
              <Link to="https://ciphersavvy.com/" target="_blank">
                {" "}
                Cipher Savvy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Mainfooter
